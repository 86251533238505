import React from 'react';
import cn from 'classnames';

import css from './Banner.module.scss';

const ID = 'div-gpt-ad-1712673826267-0';

function Banner() {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined);
  const [isClosed, setIsClosed] = React.useState(false);
  const [isRendered, setIsRendered] = React.useState(false);

  const onCloseBtnClick = () => {
    setIsClosed(true);
  };

  React.useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 768px)');
    let timeoutId: NodeJS.Timeout;

    setIsMobile(isMobile.matches);

    if (isMobile.matches) {
      timeoutId = setTimeout(() => {
        setIsClosed(true);
      }, 10000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    if (isMobile !== undefined) {
      const container = document.querySelector(`#${ID}`);
      const googletag = (window as any).googletag;
      const topWindow: any = window.top;

      if (googletag && container) {
        // When close clicked.
        topWindow.DFP ??= {};
        topWindow.DFP.onMaavaronClose = function () {
          setIsClosed(true);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.addEventListener(
          'DFPDecision',
          (e: CustomEvent) => {
            const [, , isClosed] = e.detail;
            setIsClosed(isClosed);
          },
          { signal: controller.signal },
        );

        googletag.cmd.push(function () {
          googletag
            .defineSlot('/242748299/mako/news/EX_independencedayN12/MAAVARON', [320, 410], ID)
            .setTargeting('MAKOPAGE', ['EX_independencedayN12'])
            .addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.pubads().addEventListener('slotRenderEnded', () => {
            setIsRendered(true);
          });
          googletag.enableServices();
        });

        googletag.cmd.push(function () {
          googletag.display(ID);
        });
      }
    }

    return () => {
      controller.abort();
    };
  }, [isMobile]);

  return isClosed === false ? (
    <div className={cn(css.bannerWrap, isRendered && css.rendered)}>
      <div className={css.banner}>
        <button className={css.closeBtn} onClick={onCloseBtnClick} />
        <div id={ID} style={{ minWidth: '320px', minHeight: '410px' }}></div>
      </div>
    </div>
  ) : null;
}

export default Banner;
