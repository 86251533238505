import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useAppSelector } from 'src/hooks/store';
import { useGetStory } from 'src/hooks/get-story';
import { selectConfig } from 'src/store/config/selectors';
import Icon from 'src/components/common/Icon';

import css from './StoriesNavigation.module.scss';

export const StoriesNavigation = () => {
  const { story_id } = useParams();
  const [storyData] = useGetStory({ id: story_id });
  const TEXTS = useAppSelector(selectConfig).texts.website.storyPage.navigation;
  const [isAbsolute, setIsAbsolute] = useState(false);

  useEffect(() => {
    const handler = (): void => {
      if (window) {
        const fakeBlock = document.getElementById('story-bottom');

        if (fakeBlock) {
          const fakeBlockTopPosition = window.scrollY + fakeBlock?.getBoundingClientRect().top;

          if (fakeBlock?.offsetHeight + fakeBlockTopPosition <= window.scrollY + window.innerHeight && !isAbsolute) {
            setIsAbsolute(true);
          } else if (
            fakeBlock?.offsetHeight + fakeBlockTopPosition >= window.scrollY + window.innerHeight &&
            isAbsolute
          ) {
            setIsAbsolute(false);
          }
        }
      }
    };

    // document.addEventListener('load', handler);
    document.addEventListener('scroll', handler);

    return () => {
      // document.removeEventListener('load', handler);
      document.removeEventListener('scroll', handler);
    };
  }, [isAbsolute]);

  return (
    <div className={cn(css.storiesNavigationWrap, isAbsolute && css.absolute)}>
      <div className={css.next}>
        {storyData?.prevUrl && (
          <Link to={storyData.prevUrl}>
            <Icon type="arrow-left" />
            <span>{TEXTS.previous}</span>
          </Link>
        )}
      </div>
      {storyData?.nextUrl && storyData?.prevUrl && <div className={css.line} />}
      <div className={css.previous}>
        {storyData?.nextUrl && (
          <Link to={storyData.nextUrl}>
            <span>{TEXTS.next}</span>
            <Icon type="arrow-left" />
          </Link>
        )}
      </div>
    </div>
  );
};
