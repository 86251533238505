import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { imgixUrl } from 'src/utils/imgix-url';
import { ImageCredit } from 'src/components/common/ImageCredit';

import expandIco from './assets/expand-icon.png';

const DESKTOP_HEIGHT = 485;
const MOBILE_HEIGHT = 226;
const DESKTOP_GAP = 15;
const MOBILE_GAP = 8;

interface ContainerProps {
  alignImagesToCenter: boolean;
}

const Container = styled('div', { label: 'ImagesContainer' })<ContainerProps>(({ theme, alignImagesToCenter }) => ({
  position: 'relative',
  width: '100%',
  ...(alignImagesToCenter && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),

  [theme.breakpoints.down('md')]: {
    overflow: 'scroll',
  },
}));

const ImagesWrapper = styled('div', { label: 'ImagesWrapper' })(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: `${DESKTOP_GAP}px`,

  [theme.breakpoints.down('md')]: {
    gap: `${MOBILE_GAP}px`,
  },
}));

interface ImageProps {
  className?: string;
  creditLabel: string;
  index: number;
  src: string;
  alt: string;
  width: number;
  height: number;
  onExpand?: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const Img = styled(
  ({ src, width, height, index, alt = '', className = '', creditLabel, onExpand }: ImageProps) => (
    <div
      className={className}
      style={{ width: `${width}px`, height: `${height}px`, position: 'relative' }}
      onClick={onExpand}
      data-index={index}
    >
      <button type="button" className="expand-button">
        <img src={expandIco} alt="" />
      </button>
      <img src={src} alt={alt} />
      {creditLabel && <ImageCredit text={creditLabel} style={{ textAlign: 'left', paddingRight: '50px' }} />}
    </div>
  ),
  { label: 'Img' },
)(({ theme }) => ({
  position: 'relative',
  flexShrink: 0,
  cursor: 'pointer',

  '& img': {
    display: 'block',
    width: '100%',
    height: '100%',
  },

  '& .expand-button': {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: 0,
    background: 'none',
    width: '32px',
    height: '32px',
    bottom: '6px',
    right: '6px',
    opacity: 0.8,

    '&:hover': {
      opacity: 1,
    },

    '& img': {
      width: '100%',
      height: '100%',
      display: 'block',
    },
  },
}));

export interface ImageData {
  url: string;
  credit: string;
  width: number;
  height: number;
  ratio: number;
}

const IMGIX_PARAMS = {
  'max-w': 1200,
  h: 630,
  q: 80,
  auto: 'format',
};

interface Props {
  className?: string;
  images: { url: string | undefined; credit: string }[];
  onImgExpand: (index: number) => void;
  getResizedImagesData: (data: ImageData[]) => void;
}

function Images(props: Props) {
  const { className, images } = props;
  const [imagesData, setImagesData] = React.useState<ImageData[]>([]);
  const [resizedImagesData, setResizedImagesData] = React.useState<ImageData[]>([]);
  const [isImagesDataReady, setIsImagesDataReady] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const imagesWidth =
    imagesData.reduce((acc, current) => acc + current.width, 0) +
    Math.max(imagesData.length - 1, 0) * (isMobile ? MOBILE_GAP : DESKTOP_GAP);

  const onImgExpand = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const index = Number(e.currentTarget.dataset.index);

      props.onImgExpand(index);
    },
    [imagesData, props.onImgExpand],
  );

  React.useEffect(() => {
    // reset container's height:
    if (containerRef.current) {
      containerRef.current.style.height = '';
    }

    const getImages = async () => {
      setIsImagesDataReady(false);

      // get image data for further calculations:
      const data = await Promise.all(
        images.map(
          (item) =>
            new Promise<ImageData | null>((resolve) => {
              const img = new Image();
              const url = item.url;

              if (!url) {
                resolve(null);
                return;
              }

              img.onload = () =>
                resolve({
                  url,
                  credit: item.credit,
                  width: img.width,
                  height: img.height,
                  ratio: img.width / img.height,
                });
              img.onerror = () => resolve(null);
              img.src = imgixUrl(item.url, IMGIX_PARAMS) ?? '';
            }),
        ),
      );

      const filteredData = data.filter((d) => d) as ImageData[];
      setImagesData(filteredData);
      setIsImagesDataReady(filteredData.length > 0);
    };

    getImages();
  }, [images]);

  React.useEffect(() => {
    const updateImages = async () => {
      const defaultHeight = isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT;
      const gap = isMobile ? MOBILE_GAP : DESKTOP_GAP;

      // make all images have the same height and calculate their total width:
      let totalWidth = 0;
      imagesData.forEach((d) => {
        d.height = defaultHeight;
        d.width = Math.round(d.height * d.ratio);
        totalWidth += d.width;
      });

      // calculate the scale factor:
      const totalGap = gap * (imagesData.length - 1);
      let scale = Math.min(((containerRef.current?.clientWidth ?? 0) - totalGap) / totalWidth, 1);
      if (isMobile && imagesData.length > 1) scale = 1;

      // update images' width and height according to the scale factor:
      imagesData.forEach((d) => {
        d.width = Math.round(d.width * scale);
        d.height = Math.round(d.height * scale);
      });

      // update container's height and reset scroll:
      if (containerRef.current) {
        containerRef.current.style.height = `${defaultHeight * scale}px`;
        containerRef.current.scrollTo(0, 0);
      }

      setResizedImagesData([...imagesData] as ImageData[]);
      props.getResizedImagesData([...imagesData] as ImageData[]);
    };

    if (isImagesDataReady) updateImages();
  }, [imagesData, isImagesDataReady, isMobile]);

  return (
    <Container
      className={className}
      alignImagesToCenter={(containerRef.current?.offsetWidth ?? 0) > imagesWidth}
      ref={containerRef}
    >
      {isImagesDataReady && (
        <ImagesWrapper style={{ width: imagesWidth > 0 ? `${imagesWidth}px` : '' }}>
          {resizedImagesData.map((data, index) => {
            const imgUrl = imgixUrl(data.url, IMGIX_PARAMS) ?? '';

            return (
              <Img
                src={imgUrl}
                index={index}
                width={data.width}
                height={data.height}
                creditLabel={data.credit}
                alt=""
                key={`${data.url}-${index}`}
                onExpand={onImgExpand}
              />
            );
          })}
        </ImagesWrapper>
      )}
    </Container>
  );
}

export default Images;
