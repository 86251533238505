import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import cn from 'classnames';
import * as yup from 'yup';

import { emailApi } from 'src/store/api/email';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';

import Icon from 'src/components/common/Icon';
import { TextArea } from './TextArea';

import css from './Form.module.scss';

const ERRORS = {
  required: {
    complaint: 'לצורך שליחה יש למלא מה הטעות שמצאתם',
  },
};

const validationSchema = yup.object().shape({
  complaint: yup.string().required(ERRORS.required.complaint),
});

const initialValues = {
  complaint: '',
};

type FormValues = typeof initialValues;

type Props = {
  storyId: string;
  onClose: () => void;
};

export const ComplaintForm: FC<Props> = ({ storyId, onClose }) => {
  const [sendEmail, result] = emailApi.endpoints.sendEmail.useMutation();
  const TEXTS = useAppSelector(selectConfig).texts.website.storyPage.popup;

  const onSubmit = async (values: FormValues) => {
    return await sendEmail({
      storyId,
      complaint: values.complaint,
    });
  };

  return (
    <div className={css.overlay}>
      <div className={css.mobileUnderlay} onClick={onClose} />
      <div className={css.close}>
        <button onClick={onClose}>
          <Icon type="gallery-close" />
        </button>
      </div>
      <div className={css.content}>
        <div className={css.title}>
          <button onClick={onClose}>
            <Icon type="close" />
          </button>
          <span>{TEXTS.title}</span>
        </div>

        {result.isSuccess ? (
          <div className={css.sentContent}>
            <Icon type="mark" />
            <div className={css.sentTitle}>{TEXTS.sentTexts.title}</div>
            <div className={css.sentSubtitle}>{TEXTS.sentTexts.subtitle}</div>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            <Form className={css.form}>
              <TextArea name="complaint" maxChars={400} placeholder={TEXTS.form.placeholders.complaint} />
              <div className={css.buttonWrap}>
                <button className={cn(css.submit, result.isLoading && css.disabled)} type="submit">
                  <span>{TEXTS.form.submitBtn}</span> <Icon type="arrow-left" />
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};
