import React from 'react';
import { useLocation } from 'react-router';
import { Pagination } from '@mui/material';
import qs from 'qs';
import cn from 'classnames';

import { QS_STRINGIFY_OPTIONS } from '@api/constants/other';

import { selectConfig } from 'src/store/config/selectors';
import { useAppSelector } from 'src/hooks/store';

import { useSearch, PAGE_SIZE } from '../FilterSection/Search/Context';
import { LoaderItem, LoaderSearchInfoBlock } from './Loader';
import { ListItem } from './ListItem';

import css from './List.module.scss';

export const List = () => {
  const location = useLocation();
  const search = useSearch();
  const t = useAppSelector(selectConfig).texts.website.main.header;
  const items = search.result?.success ? search.result.data.items : [];
  const totalItems = search.result?.success ? search.result.data.page.total : 0;
  const totalPages = Math.ceil(totalItems / PAGE_SIZE);
  const onScrollClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const handleChange = (e: React.ChangeEvent<unknown>, value: number) => {
    search.formik.setFieldValue('page', value);
    search.formik.submitForm();
    onScrollClick();
  };

  const getAmountText = (value: number | undefined) => {
    if (typeof value !== 'undefined' && value > 0) return `נמצאו ${value} תוצאות עבור:`;

    return t.noResult;
  };

  const renderSearchParams = () => {
    const urlParams = qs.parse(location.search.replace('?', ''), QS_STRINGIFY_OPTIONS) as any;

    const query = urlParams?.query as string;
    const valuesToRender: string[] = [];

    if (query) valuesToRender.push(query);

    return (
      <div className={css.searchParams}>
        <div className={css.searchParamsItem}>{valuesToRender.map((v) => `"${v}"`).join(', ')}</div>
      </div>
    );
  };

  return (
    <div className={css.listWrapper}>
      {search.isSearchActive && (
        <>
          {search.isFetching ? (
            <LoaderSearchInfoBlock className={css.resultsAmountWrapper} />
          ) : (
            <div className={css.resultsAmountWrapper}>
              <div className={cn(css.resultsAmount, !totalItems && css.noResults)}>
                <span>{getAmountText(totalItems)}</span>
              </div>
              {totalItems > 0 && renderSearchParams()}
            </div>
          )}
        </>
      )}
      <div className={css.list}>
        {search.isFetching ? (
          <>
            <LoaderItem />
            <LoaderItem />
            <LoaderItem />
            <LoaderItem />
          </>
        ) : (
          items.map((item) => <ListItem data={item} key={item._id.toString()} />)
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={search.formik.values.page}
          onChange={handleChange}
          className={css.pageSwitcher}
          shape="rounded"
          size="small"
        />
      )}
    </div>
  );
};
