import React from 'react';
import cn from 'classnames';

import css from './Loader.module.scss';

interface Props {
  color?: 'white' | 'blue';
}

export const Loader = (props: Props) => {
  return (
    <div className={cn(css.ldsRing, css[props.color ?? 'white'])}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
