import React from 'react';

import { useResponsive } from 'src/hooks/responsive';
import formBg from 'src/assets/formSection/formBg.jpeg';
import css from './FormSection.module.scss';

export const FormSection = () => {
  const [isMobile] = useResponsive('MOBILE');
  const formWrapperRef = React.useRef<HTMLDivElement>(null);
  let formUrl = '';

  switch (process.env.ENV) {
    case 'production':
      formUrl = 'https://id2024form.mako.co.il?builtin=1';
      break;

    case 'development':
      formUrl = `https://${process.env.PROJECT_ID}.firebaseapp.com?builtin=1`;
      break;

    case 'local':
      formUrl = `http://localhost:${process.env.CLIENT_FORM_WEBPACK_DEV_PORT}?builtin=1`;
      break;

    default:
      break;
  }

  React.useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.data.type === 'step-changed') {
        const formWrapperOffset = formWrapperRef?.current?.offsetTop;
        if (formWrapperOffset) {
          window.scrollTo({
            top: formWrapperOffset,
            behavior: 'smooth',
          });
        }
      }
    });
  }, []);

  return (
    <div className={css.formSection} ref={formWrapperRef} id="form-section">
      <div className={css.background} style={{ backgroundImage: `url(${formBg})` }}></div>
      <div className={css.form}>
        <iframe src={formUrl} />
      </div>
    </div>
  );
};
