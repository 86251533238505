import React from 'react';

export const Outbrain = () => {
  const markerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const controller = new AbortController();
    const OUTBRAIN_SCRIPT_ID = 'outbrain-script';

    window.addEventListener(
      'load',
      () => {
        const isMobile = window.matchMedia('(max-width: 1023px)').matches;
        const wrapper = document.querySelector('.outbrain-wrap');
        const script = document.createElement('script');
        const outbrainContainer = document.createElement('div');

        if (!wrapper) {
          return;
        }

        script.src = 'https://widgets.outbrain.com/outbrain.js';
        script.async = true;
        script.id = OUTBRAIN_SCRIPT_ID;

        outbrainContainer.classList.add('OUTBRAIN');
        outbrainContainer.setAttribute('data-src', 'https://story.mako.co.il/idf_warmaterials');
        outbrainContainer.setAttribute('data-widget-id', isMobile ? 'MB_33' : 'SF_5');

        wrapper.appendChild(outbrainContainer);
        document.body.appendChild(script);

        controller.abort();
      },
      { signal: controller.signal },
    );

    return () => {
      controller.abort();

      const script = document.getElementById(OUTBRAIN_SCRIPT_ID);
      if (script) document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '-300px' }} ref={markerRef} />
    </div>
  );
};

export default Outbrain;
