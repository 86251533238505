import api from '.';
import { ROUTE, Params, Result } from '@api/routes/public/email/send-email/params';

import { ApiResponse } from '@api/types';

export const emailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation<ApiResponse<Result>, Params>({
      query: (params) => ({
        method: 'POST',
        url: ROUTE,
        body: params,
      }),
    }),
  }),
});
