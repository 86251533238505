import React from 'react';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';

import css from './Loader.module.scss';

export const LoaderItem = () => {
  return (
    <div className={css.loaderItem}>
      <ContentLoader
        speed={2}
        width={331}
        height={420}
        viewBox="0 0 331 420"
        backgroundColor="#E6F4FF"
        foregroundColor="#dceaff"
      >
        <rect x="487" y="542" rx="2" ry="2" width="140" height="10" />
        <rect x="-2" y="49" rx="10" ry="10" width="329" height="207" />
        <rect x="0" y="2" rx="10" ry="10" width="328" height="23" />
        <rect x="14" y="279" rx="10" ry="10" width="313" height="15" />
        <rect x="41" y="306" rx="10" ry="10" width="286" height="15" />
        <rect x="213" y="341" rx="10" ry="10" width="112" height="24" />
        <rect x="133" y="341" rx="10" ry="10" width="76" height="24" />
        <rect x="1" y="383" rx="10" ry="10" width="327" height="35" />
      </ContentLoader>
    </div>
  );
};

type Props = {
  className?: string;
};

export const LoaderSearchInfoBlock: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={cn(css.loader, className)}>
      <ContentLoader
        speed={2}
        width={265}
        height={35}
        viewBox="0 0 265 35"
        backgroundColor="#FFF"
        foregroundColor="#dceaff"
      >
        <rect x="0" y="0" rx="10" ry="10" width="60" height="35" />
        <rect x="65" y="0" rx="10" ry="10" width="200" height="35" />
      </ContentLoader>
    </div>
  );
};
