import cn from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { AggregatedSubmission } from '@api/routes/public/submissions/paginate/params';

import { imgixUrl } from 'src/utils/imgix-url';
import { analytics } from 'src/utils/analytics';
import { getCroppedPhoto } from 'src/utils/get-cropped-photo';
import { getKeyFromImageUrl } from 'src/utils/get-key-from-image-url';

import { ImageCredit } from 'src/components/common/ImageCredit';
import Icon from 'src/components/common/Icon';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';

import css from './ListItem.module.scss';

type Props = {
  data: AggregatedSubmission;
};

export const ListItem: FC<Props> = (props) => {
  const { data } = props;

  const location = useLocation();
  const TEXTS = useAppSelector(selectConfig).texts.website.main;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const imageCredit =
    data.memory?.metadata?.imageCredit?.[
      getKeyFromImageUrl(
        data.memory?.photo || data.memory?.photos?.[0]?.url || data.memory?.photosUploadedByAdmin?.[0]?.url || '',
      )
    ] ?? '';
  const imgUrl = useMemo(() => {
    const cropped = data.memory?.photo
      ? getCroppedPhoto(data.memory?.photo, 'mainPhoto', data.memory?.metadata)
      : data.memory?.photos?.[0]?.url;
    const imgixParams = {
      w: 330,
      h: 207,
      auto: 'format,compress',
      ...(!data.disableFaceRecognition && { fit: 'facearea,crop', facepad: 100 }),
    };
    let result = imgixUrl(cropped || data.memory?.photo || data.memory?.photos?.[0]?.url, imgixParams);

    if (data.memory?.photos) {
      if (data.memory?.photo && data.memory.metadata?.hidePhoto) {
        const hide = data.memory.metadata?.hidePhoto.find((photo) => photo === data.memory?.photo);

        if (hide) result = '';
      }
    }

    return result;
  }, [data]);

  const sliderItems = useMemo(() => {
    if (data.children) {
      return [data, ...data.children];
    }
    return [data];
  }, [data]);
  const storyRoute = `/stories/${sliderItems[currentSlideIndex]._id.toString()}${location.search}`;
  const title = data.soldier.name;
  const sender = sliderItems[currentSlideIndex].contact.name || '';
  const story = sliderItems[currentSlideIndex].memory?.story || '';

  const onStoryClick = () => {
    analytics.gtag.event('full story', { CUSTOM_PARAMETER: sender || '' });
  };

  const onSliderNavBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { dir } = e.currentTarget.dataset;
    const inc = dir === 'prev' ? -1 : 1;
    const nextIndex = (currentSlideIndex + inc) % sliderItems.length;

    setCurrentSlideIndex(nextIndex < 0 ? sliderItems.length - 1 : nextIndex);
  };

  const renderSliderControls = () => {
    return (
      <div className={css.controls}>
        <button type="button" data-dir="prev" onClick={onSliderNavBtnClick} className={cn(css.nav, css.prev)} />
        <div className={css.itemsInfo}>{`${currentSlideIndex + 1}/${sliderItems.length} ${
          TEXTS.list.listItem.slidesInfo
        }`}</div>
        <button onClick={onSliderNavBtnClick} type="button" data-dir="next" className={cn(css.nav, css.next)} />
      </div>
    );
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={sliderItems[currentSlideIndex]._id.toString()}
        timeout={400}
        classNames={{
          enter: css.fadeEnter,
          enterActive: css.fadeEnterActive,
          enterDone: css.fadeEnterDone,
          exit: css.fadeExit,
          exitActive: css.fadeExitActive,
          exitDone: css.fadeExitDone,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={cn(css.listItem, sliderItems.length > 1 && css.withSlider)}>
          <div className={css.title}>
            <Link to={storyRoute} title={title} onClick={onStoryClick}>
              <span>{title}</span>
            </Link>
          </div>
          <div className={css.sender}>
            {TEXTS.list.listItem.sender} {sender}
          </div>
          {imgUrl && (
            <div className={css.photo}>
              <Link to={storyRoute} title={title} onClick={onStoryClick}>
                <>
                  <img
                    style={process.env.ENV !== 'production' ? { objectFit: 'cover' } : undefined}
                    src={imgUrl}
                    alt={title}
                  />
                  {imageCredit && <ImageCredit textAlign="right" className={css['image-credit']} text={imageCredit} />}
                </>
              </Link>
            </div>
          )}
          {sliderItems.length > 1 && renderSliderControls()}
          <Link to={storyRoute} title={title} onClick={onStoryClick}>
            <div className={cn(css.subtitle, !imgUrl && css.opened)}>
              <span dangerouslySetInnerHTML={{ __html: story }} />
            </div>
          </Link>
          <div className={css.button}>
            <Link to={storyRoute} title={title} onClick={onStoryClick}>
              <span>{TEXTS.list.listItem.button}</span>
              <Icon type="arrow-left" secondaryColor="#FFFFFF" />
            </Link>
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
