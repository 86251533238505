import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TEXTS as CLIENT_FORM_TEXTS } from '@api/data/texts/client-form';
import { TEXTS as WEBSITE_TEXTS } from '@api/data/texts/website';
import { Init } from './actions';

export type ConfigState = Init;

const initialState: ConfigState = {
  battles: {},
  divisions: {},
  soldiers: {},
  units: {},
  submissionTags: {},
  texts: {
    clientForm: CLIENT_FORM_TEXTS,
    website: WEBSITE_TEXTS,
  },
  seo_og: {
    seo: {
      _id: 'seo',
      mainPage: {
        title: '',
        description: '',
      },
      storyPage: {
        title: '',
        description: '',
      },
    },
    og: {
      _id: 'og',
      mainPage: {
        title: '',
        description: '',
        imageUrl: '',
      },
      storyPage: {
        title: '',
        description: '',
      },
    },
  },
};

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Init>) => {
      return action.payload;
    },
  },
});

export const { init } = config.actions;
export default config.reducer;
