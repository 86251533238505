import qs from 'qs';

import { FormValues } from './types';

export function queryString2FormValues(queryString: string) {
  const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true }) as any;
  const nextFormValues: FormValues = {
    query: parsedParams.query || '',
    page: parsedParams.page ? parseInt(parsedParams.page) : 1,
  };

  return nextFormValues;
}

export function formValues2QueryData(values: FormValues) {
  return {
    query: values.query || undefined,
    page: values.page,
  };
}
