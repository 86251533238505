import React, { ChangeEvent, FC } from 'react';
import { useField } from 'formik';

import css from './TextArea.module.scss';

type Props = {
  name: string;
  maxChars: number;
  placeholder?: string;
};

export const TextArea: FC<Props> = ({ name, maxChars, placeholder }) => {
  const [field, meta, helpers] = useField(name);
  const shouldRenderError = !!(meta.touched && meta.error);
  const wordsAmount = getWordsAmount(field.value || '');

  const processTextChange = (text: string) => {
    const newAmount = getWordsAmount(text);

    if (maxChars && newAmount > maxChars) {
      const splittedWords = text.split(' ');

      helpers.setValue(splittedWords.slice(0, maxChars).join(' '));
      return;
    }

    helpers.setValue(text);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (maxChars && getWordsAmount(field.value || '') >= maxChars) {
        e.preventDefault();
        return;
      }
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (maxChars && getWordsAmount(field.value || '') > maxChars) {
      e.preventDefault();
      return;
    }
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    processTextChange(e.target.value);
  };

  return (
    <div className={css.textareaWrap}>
      <div className={css.textArea}>
        <textarea
          {...field}
          onChange={onChange}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          placeholder={placeholder || ''}
        />
        <div className={css.bottomWrap}>
          <div className={css.error}>{shouldRenderError && <p>{meta.error}</p>}</div>
          <div className={css.counter}>
            <span>
              {wordsAmount}/{maxChars}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

function getWordsAmount(text: string) {
  return text.split(' ').filter((s) => !!s.trim()).length ?? 0;
}
