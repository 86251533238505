import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { CAN_USE_DOM } from 'src/constants/other';

import { submissions } from 'src/store/api/submissions';

export const useGetStory = ({ id }: { id: string | undefined }) => {
  const location = useLocation();
  const { data, isFetching } = submissions.endpoints.getSubmission.useQuery({
    storyData_id: id || '',
    search: CAN_USE_DOM ? location.search : '',
  });
  const result = useMemo(() => (data?.success ? data.data : null), [id, data, isFetching]);

  return [result, isFetching] as const;
};
