import React, { FC } from 'react';
import cn from 'classnames';

import { Loader } from '../Loader';

import css from './Fallback.module.scss';

type Props = {
  className?: string;
  color?: 'white' | 'blue';
};

export const Fallback: FC<Props> = ({ className, color }) => {
  return (
    <div className={cn(css.fallback, className)}>
      <div className={css.loader}>
        <Loader color={color} />
      </div>
    </div>
  );
};
