import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router';
import qs from 'qs';

import { QS_STRINGIFY_OPTIONS } from '@api/constants/other';

import Head from 'src/components/common/Head';
import { DOMAIN_URL } from 'src/constants/other';
import { Header } from 'src/components/common/Header';
import { Footer } from 'src/components/common/Footer';
import { Fallback } from 'src/components/common/Fallback';

import { imgixUrl } from 'src/utils/imgix-url';
import { useAppSelector } from 'src/hooks/store';
import { useGetStory } from 'src/hooks/get-story';
import Cooladata from 'src/components/common/Cooladata';
import { selectConfig } from 'src/store/config/selectors';
import { getCroppedPhoto } from 'src/utils/get-cropped-photo';
import { getKeyFromImageUrl } from 'src/utils/get-key-from-image-url';

import { Gallery } from './Gallery';
import { Socials } from './Socials';
import { ComplaintForm } from './Form';
import Images, { ImageData } from './Images';
import { StoriesNavigation } from './StoriesNavigation';

import css from './StoryPage.module.scss';

export const StoryPage = () => {
  const location = useLocation();
  const { story_id } = useParams();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [chosenImageIndex, setChosenImageIndex] = useState(0);
  const [processedImages, setProcessedImages] = useState<ImageData[]>([]);
  const [storyData, isFetching] = useGetStory({ id: story_id });
  const TEXTS = useAppSelector(selectConfig).texts.website.storyPage;
  const { seo, og } = useAppSelector(selectConfig).seo_og;

  const urlParams = qs.parse(location.search.replace('?', ''), QS_STRINGIFY_OPTIONS) as any;
  const shouldRenderNavigation = !(urlParams?.query || Object.keys(urlParams?.filters || {}).length > 0);
  const storyImages = React.useMemo(
    () => [...(storyData?.memory?.photos || []), ...(storyData?.memory?.photosUploadedByAdmin || [])],
    [storyData],
  );

  const images = React.useMemo(() => {
    const visiblePhotos: {
      url: string | undefined;
      credit: string;
    }[] = [];
    const images =
      storyImages.map((img) => ({
        url: getCroppedPhoto(img.url, 'photo', storyData?.memory?.metadata) || img.url,
        credit: storyData?.memory?.metadata?.imageCredit?.[getKeyFromImageUrl(img.url || '')] || '',
      })) || [];

    images.forEach((item) => {
      const isImageHidden = storyData?.memory?.metadata?.hidePhoto?.find((photo) => photo === item.url);
      if (!isImageHidden && item.url) visiblePhotos.push(item);
    });
    return visiblePhotos;
  }, [storyData]);

  const toggleGallery = (isOpen: boolean) => () => {
    setIsGalleryOpen(isOpen);
  };

  const toggleForm = (isOpen: boolean) => () => {
    setIsFormOpen(isOpen);
  };

  const getResizedImagesData = (data: ImageData[]) => {
    setProcessedImages(data);
  };
  const onImageExpand = React.useCallback(
    (index: number) => {
      console.log('index', index);

      setChosenImageIndex(index);
      setIsGalleryOpen(true);
    },
    [storyData],
  );

  const getOgImgUrl = () => {
    const imgixParams = { 'max-w': 1200, h: 630, q: 80, auto: 'format' };

    if (storyData?.memory?.photo) {
      return storyData?.memory?.photo;
    } else if (storyData?.memory?.photos && storyData?.memory?.photos[0] && storyData?.memory?.photos[0].url) {
      return imgixUrl(storyData?.memory?.photos[0].url, imgixParams);
    }
    return imgixUrl(seo.mainPage.imageUrl, imgixParams);
  };

  const HeadProps = {
    seo: {
      title: seo.storyPage.title.replace('{name}', storyData?.soldier.name || ''),
      description: seo.storyPage.description.replace('{name}', storyData?.soldier.name || ''),
    },
    og: {
      title: og.storyPage.title.replace('{name}', storyData?.soldier.name || ''),
      description: og.storyPage.description.replace('{name}', storyData?.soldier.name || ''),
      imageUrl: getOgImgUrl(),
    },
  };

  return (
    <>
      <Head {...HeadProps}>
        <link rel="canonical" href={`${DOMAIN_URL}${location.pathname}`}></link>
      </Head>
      <main className={css.page} id="top">
        <Header />
        <section className={css.storyContainer}>
          {isFetching && <Fallback className={css.fallback} color="blue" />}
          <div className={css.content}>
            <div className={css.headline}>
              <div className={css.texts}>
                <h1 className={css.title}>{storyData?.soldier.name || ''}</h1>
                <div className={css.sender}>
                  {TEXTS.sender} {storyData?.contact.name}
                </div>
              </div>
              <div className={css.socials}>
                <Socials soldierName={storyData?.soldier.name} />
              </div>
            </div>
            <div className={css.imagesContainer}>
              <Images images={images} onImgExpand={onImageExpand} getResizedImagesData={getResizedImagesData} />
            </div>
            <div className={css.story}>
              {storyData?.memory?.story && <span dangerouslySetInnerHTML={{ __html: storyData?.memory?.story }} />}
            </div>
          </div>
        </section>
        {!isFetching && shouldRenderNavigation && <StoriesNavigation />}
        <div id="story-bottom" className={css.storyBottom} />
        <Footer storyTitle={storyData?.soldier.name} />
      </main>
      {storyData && storyImages && isGalleryOpen && (
        <Gallery
          chosenImageIndex={chosenImageIndex}
          photos={processedImages.map((image) => ({ url: image.url, credit: image.credit }))}
          onClose={toggleGallery(false)}
        />
      )}
      {isFormOpen && <ComplaintForm storyId={story_id!} onClose={toggleForm(false)} />}
      <Cooladata pageId="content_page" />
    </>
  );
};
