import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { selectConfig } from 'src/store/config/selectors';
import { useAppSelector } from 'src/hooks/store';

import { useSearch } from './Search/Context';
import Search from './Search';

import css from './FilterSection.module.scss';
type Props = {
  id: string;
};

export const FilterSection: React.FC<Props> = ({ id }) => {
  const search = useSearch();
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const t = useAppSelector(selectConfig).texts.website.main.header;
  const totalItems = search.result?.success ? search.result.data.page.total : 0;

  useEffect(() => {
    const onPageLoad = () => {
      if (document.readyState === 'complete') {
        setIsPageLoaded(true);
      }
    };

    if (document.readyState === 'complete') {
      setIsPageLoaded(true);
    }

    window.addEventListener('load', onPageLoad, false);
    return () => window.removeEventListener('load', onPageLoad);
  }, [isPageLoaded]);

  return (
    <section className={css.filterSection} id={id}>
      <div className={cn(css.content, !totalItems && css.noSearchResults)}>
        <div className={css.title}>
          <span>{t.title}</span>
        </div>
        <Search />
      </div>
    </section>
  );
};
