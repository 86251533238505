import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { ROOT } from 'src/constants/routes';
import Icon from 'src/components/common/Icon';
import { analytics } from 'src/utils/analytics';
import { imgixUrl } from 'src/utils/imgix-url';

import { useAppSelector } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';
import { selectConfig } from 'src/store/config/selectors';

import headerLogoSrc from 'src/assets/header/headerLogo.svg';
import nLogoSrc from 'src/assets/header/nlogo.svg';

import css from './Header.module.scss';

export const Header = () => {
  const [isBackground, setIsBackground] = useState<boolean>(false);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [isMobile] = useResponsive('MOBILE');
  const { story_id } = useParams();
  const navigate = useNavigate();
  const TEXTS = useAppSelector(selectConfig).texts.website;
  const nLogo = imgixUrl(nLogoSrc);
  const headerLogo = imgixUrl(headerLogoSrc);

  const onScrollDownClick = () => {
    const formSection = document.querySelector('#form-section');
    if (formSection) formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onScrollUpClick = () => {
    const top = document.querySelector('#top');
    if (top) top.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    const controller = new AbortController();
    const handler = (e: Event) => {
      const target = e.currentTarget as Window;

      if (target.scrollY > 10 && !isBackground) {
        setIsBackground(true);
      } else if (target.scrollY <= 10 && isBackground) {
        setIsBackground(false);
      }
    };

    window.addEventListener('scroll', handler, { signal: controller.signal });
    return () => controller.abort();
  }, [isBackground]);

  useEffect(() => {
    const controller = new AbortController();
    const onPageLoad = () => {
      setIsPageLoaded(true);
      controller.abort();
    };

    if (document.readyState === 'complete') {
      setIsPageLoaded(true);
    }

    window.addEventListener('load', onPageLoad, { signal: controller.signal });
    return () => controller.abort();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [story_id]);

  const onBackBtnClick = () => {
    if (window.history.length === 2) {
      navigate(ROOT, { state: { scrollToGrid: true } });
    } else {
      window.history.back();
    }
  };

  return (
    <header className={cn(css.header, (story_id && isMobile && css.background) || (isBackground && css.background))}>
      <a
        className={cn(css.n12LogoWrap)}
        href="https://n12.co.il/"
        target="_blank"
        rel="noreferrer"
        onClick={() => analytics.gtag.event('Click logo')}
      >
        <img src={nLogo} alt="N12" />
      </a>
      {(!isMobile || story_id) && (
        <h1 className={cn(css.logoWrapper, ((!isMobile && isBackground) || (!isMobile && story_id)) && css.show)}>
          <Link to="..">
            <img
              src={headerLogo}
              alt="הזיכרון שלי מלחמת יום הכיפורים"
              title="הזיכרון שלי מלחמת יום הכיפורים"
              onClick={onScrollUpClick}
              className={css.innerLogo}
            />
          </Link>
        </h1>
      )}
      {story_id ? (
        <div className={css.back} onClick={onBackBtnClick}>
          <span>{TEXTS.main.header.backBtn}</span>
          <Icon type="arrow-left" secondaryColor="#fff" />
        </div>
      ) : (
        <button onClick={onScrollDownClick} className={cn(!isPageLoaded && css.hideText)}>
          <span>{TEXTS.main.header.scrollDownBtn}</span>
          <Icon type="arrow-left" secondaryColor="#03337D" />
        </button>
      )}
    </header>
  );
};
