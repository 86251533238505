import React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';

import Flex from 'src/components/common/Flex';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { useSearch } from './Context';
import { useResponsive } from 'src/hooks/responsive';

const Container = styled(
  (props: React.PropsWithChildren & { className?: string }) => (
    <Flex {...props} justifyContent="space-between" alignItems="center">
      {props.children}
    </Flex>
  ),
  { label: 'SearchContainer' },
)(({ theme }) => ({
  position: 'relative',
  width: '574px',
  height: '51px',
  padding: '6px',
  borderRadius: '10px',
  border: `1px solid ${theme.palette.other.formBorderLight}`,
  backgroundColor: '#FFFFFF',
  color: '#04476D',
  [theme.breakpoints.down(1180)]: {
    width: '456px',
  },

  [theme.breakpoints.down(1153)]: {
    width: '356px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },

  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));

const SearchInput = styled(InputBase, { label: 'SearchInput' })({
  color: 'inherit',
  flexGrow: 1,

  '& input': {
    fontSize: '18px',
    lineHeight: '19.71px',
    textAlign: 'right',
    padding: '0',
    transform: 'translateY(1px)',
  },
});

const SearchButton = styled('button', { label: 'SearchButton' })({
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  marginLeft: '1px',
  padding: '0',
  border: 'none',
  borderRadius: '50%',
  color: '#7D8D96',
  backgroundColor: 'transparent',

  '& img': {
    position: 'relative',
    display: 'block',
    width: '19px',
    height: '19px',
  },

  '&:hover': {
    '& path': {
      fill: '#053550',
    },
  },

  '&:disabled': {
    cursor: 'default',
    pointerEvents: 'none',
  },
});

const SearchIcon = styled(
  ({ color = '#7D8D96', ...props }: { color?: string }) => (
    <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.65 0C2.98856 0 0 2.98856 0 6.65C0 10.3114 2.98856 13.3 6.65 13.3C8.3106 13.3 9.82776 12.6806 10.9955 11.6672L11.4 12.0717V13.3L17.1 19L19 17.1L13.3 11.4H12.0717L11.6672 10.9955C12.6806 9.82776 13.3 8.3106 13.3 6.65C13.3 2.98856 10.3114 0 6.65 0ZM6.65 1.9C9.2846 1.9 11.4 4.01539 11.4 6.65C11.4 9.2846 9.2846 11.4 6.65 11.4C4.01539 11.4 1.9 9.2846 1.9 6.65C1.9 4.01539 4.01539 1.9 6.65 1.9Z"
        fill={color}
      />
    </svg>
  ),
  { label: 'SearchIcon' },
)(({ theme }) => ({
  position: 'relative',
  display: 'block',

  '& path': {
    transition: 'fill 0.3s',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '5px',
    marginLeft: '4px',
  },
}));

interface Props {
  className?: string;
}

function Search(props: Props) {
  const { className = '' } = props;
  const t = useAppSelector(selectConfig).texts.website;
  const search = useSearch();
  const [isSmallMobile] = useResponsive('ULTRA_SMALL_MOBILE');
  const isInProgress = search.isFetching;

  const placeholderText = isSmallMobile
    ? t.main.header.search.placeholderSmallMobile
    : t.main.header.search.placeholder;

  const onSearchInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      search.formik.setFieldValue('query', e.target.value);
    },
    [search.formik],
  );

  const onSubmitBtnClick = React.useCallback(() => {
    search.formik.setFieldValue('page', 1);
    // don't submit formik here, because it will be submitted on form submit, this btn hase type="submit"
  }, [search.formik]);

  return (
    <>
      <form onSubmit={search.formik.handleSubmit}>
        <Container className={cn(className)}>
          <Flex width="100%" alignItems="center">
            <SearchButton disabled={isInProgress} type="submit" onClick={onSubmitBtnClick}>
              {!isInProgress ? <SearchIcon /> : <CircularProgress size={20} color="inherit" />}
            </SearchButton>
            <SearchInput
              placeholder={placeholderText}
              value={search.formik.values.query}
              onChange={onSearchInputChange}
            />
          </Flex>
        </Container>
      </form>
    </>
  );
}

export default Search;
