import React, { useState, FC, useRef } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import { useResponsive } from 'src/hooks/responsive';
import { useOnClickOutside } from 'src/hooks/click-uotside';

import Icon from 'src/components/common/Icon';

import css from './Gallery.module.scss';
import { ImageCredit } from 'src/components/common/ImageCredit';

type Props = {
  photos: { url: string; credit: string }[];
  onClose: () => void;
  chosenImageIndex: number;
};

export const Gallery: FC<Props> = ({ photos, onClose, chosenImageIndex }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(chosenImageIndex);
  const [isSmallMobile] = useResponsive('MOBILE');
  const galleryRef = useRef<HTMLDivElement>(null);
  const isArrowsHidden = photos.length === 1;

  const nextImage = () => {
    setCurrentImageIndex((prevState) => (prevState === photos.length - 1 ? 0 : prevState + 1));
  };

  const previousImage = () => {
    setCurrentImageIndex((prevState) => (prevState === 0 ? photos.length - 1 : prevState - 1));
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentImageIndex,
    afterChange: (index: number) => {
      setCurrentImageIndex(index);
    },
  };

  useOnClickOutside(galleryRef, onClose);

  return (
    <section className={css.gallery}>
      <div className={css.content} ref={galleryRef}>
        <div className={css.header}>
          <button className={css.close} onClick={onClose}>
            <Icon type="gallery-close" />
          </button>
          <div className={css.imageAmount}>
            <span>
              {currentImageIndex + 1}/{photos.length}
            </span>
          </div>
        </div>
        <div className={css.images}>
          <button onClick={nextImage} className={cn(isArrowsHidden && css.hideArrow)}>
            <Icon type="blue-arrow-right" />
          </button>

          {isSmallMobile ? (
            <Slider {...settings} className={css.slider} arrows={false}>
              {photos.map((item, index) => (
                <div className={css.imageContainer} key={`${item.url}/${index}`}>
                  <div className={css.imageContainerInner}>
                    <img src={item.url} alt="" />
                    {item.credit && <ImageCredit text={item.credit} textAlign="right" />}
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className={css.imageContainer}>
              <div className={css.imageContainerInner}>
                <img src={photos[currentImageIndex].url} alt="" />
                {photos[currentImageIndex].credit && <ImageCredit text={photos[currentImageIndex].credit} />}
              </div>
            </div>
          )}

          <button onClick={previousImage} className={cn(isArrowsHidden && css.hideArrow)}>
            <Icon type="blue-arrow-left" />
          </button>
        </div>
      </div>
    </section>
  );
};
