import React, { useState } from 'react';
import cn from 'classnames';

import { share } from 'src/utils/share';
import { analytics } from 'src/utils/analytics';
import { SHARE_CONSTANTS } from 'src/constants/other';
import { useResponsive } from 'src/hooks/responsive';

import Icon from 'src/components/common/Icon';

import css from './Socials.module.scss';

interface Props {
  soldierName?: string;
}

export const Socials = (props: Props) => {
  const [isMobile] = useResponsive('MOBILE');
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const clickOnShare = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset.id;

    if (type === 'Facebook' || type === 'Twitter' || type === 'E-mail' || type === 'whatsApp') {
      share({ type });

      analytics.gtag.event('Share story', {
        CUSTOM_PARAMETER: props.soldierName || '',
        CUSTOM_PARAMETER2: type?.toLowerCase().replaceAll('-', '') || '',
      });
    }
  };

  return (
    <>
      <div className={cn(css.socials, (isMobile === undefined || isMobile) && css.minified, isOpen && css.open)}>
        {isMobile && (
          <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.WHATS_APP} onClick={clickOnShare}>
            <Icon type="whatsapp" className={cn(css.icon, css.whatsApp)} />
          </div>
        )}
        <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.MAIL} onClick={clickOnShare}>
          <Icon type="inner-email" className={cn(css.icon, css.email)} />
        </div>
        <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.TWITTER} onClick={clickOnShare}>
          <Icon type="inner-twitter" className={cn(css.icon, css.twitter)} />
        </div>
        <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.FACEBOOK} onClick={clickOnShare}>
          <Icon type="inner-facebook" className={cn(css.icon, css.facebook)} />
        </div>
        {!isMobile && (
          <div className={cn(css.shareIcon)} data-id={SHARE_CONSTANTS.WHATS_APP} onClick={clickOnShare}>
            <Icon type="whatsapp" className={cn(css.icon, css.whatsApp)} />
          </div>
        )}
      </div>
      {isMobile && (
        <div className={cn(css.toggleBtn, isOpen && css.open)} onClick={toggleOpenMenu}>
          <Icon type="share" className={css.iconToggle} />
        </div>
      )}
    </>
  );
};
