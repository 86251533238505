import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';

import { ROOT, STORY_ROUTE } from 'src/constants/routes';
import PageLayout from 'src/components/common/PageLayout';
import Main from 'src/components/pages/Main';
import { StoryPage } from './components/pages/StoryPage';

export const Routes = (
  <>
    <Route path={ROOT} element={<PageLayout />}>
      <Route path={ROOT} element={<Main />} />
      <Route path={STORY_ROUTE} element={<StoryPage />} />
    </Route>
  </>
);

export const routes = createRoutesFromElements(Routes);
export default routes;
