import React from 'react';
import { useResponsive } from 'src/hooks/responsive';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { imgixUrl } from 'src/utils/imgix-url';

import topSectionBgSrc from 'src/assets/topSection/topSectionBg.jpeg';
import topSectionBgMobileSrc from 'src/assets/topSection/topSectionBgMobile.jpeg';
import logoSrc from 'src/assets/topSection/Logo.svg';
import mobileLogoSrc from 'src/assets/topSection/mobile-logo.svg';

import css from './TopSection.module.scss';

const TopSection = () => {
  const [isMobile] = useResponsive('MOBILE');
  const TEXTS = useAppSelector(selectConfig).texts.website;
  const text = isMobile ? TEXTS.main.topSection.mobileText : TEXTS.main.topSection.text;
  const topSectionBgMobile = imgixUrl(topSectionBgMobileSrc);
  const topSectionBg = imgixUrl(topSectionBgSrc);
  const bg = isMobile ? topSectionBgMobile : topSectionBg;
  const logo = isMobile ? imgixUrl(mobileLogoSrc) : imgixUrl(logoSrc);

  return (
    <div className={css.topSection} style={{ backgroundImage: `url(${bg})` }}>
      <div className={css.info}>
        {isMobile !== undefined && <img src={logo} alt="logo" className={css.logo} />}
        <div className={css.text} dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>

      {isMobile && <div className={css.arrow} />}
    </div>
  );
};

export default TopSection;
