import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useLocation } from 'react-router-dom';

import 'src/styles/index.scss';
import { theme } from 'src/theme';
import Head from 'src/components/common/Head';
import Banner from 'src/components/common/Banner';
import useUpdateEffect from 'src/hooks/useUpdateEffect';

const PageLayout = () => {
  const location = useLocation();
  const [isShowBanner, setIsShowBanner] = React.useState(true);

  useUpdateEffect(() => {
    isShowBanner && setIsShowBanner(false);
  }, [location]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Head />
        {isShowBanner && <Banner />}
        <Outlet />
      </ThemeProvider>
    </>
  );
};

export default PageLayout;
