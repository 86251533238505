import React from 'react';

import { share } from 'src/utils/share';
import { analytics } from 'src/utils/analytics';
import { SHARE_CONSTANTS } from 'src/constants/other';
import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { imgixUrl } from 'src/utils/imgix-url';

import logoBlueSrc from 'src/assets/footer/logoBlue.svg';
import { useResponsive } from 'src/hooks/responsive';
import Icon from 'src/components/common/Icon';

import css from './Footer.module.scss';

interface Props {
  storyTitle?: string;
}

export const Footer = (props: Props) => {
  const TEXTS = useAppSelector(selectConfig).texts.website;
  const { og } = useAppSelector(selectConfig).seo_og;
  const [isMobile] = useResponsive('MOBILE');
  const logoBlue = imgixUrl(logoBlueSrc);

  const clickOnShare = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset.id;

    if (type === 'Facebook' || type === 'Twitter' || type === 'E-mail') {
      share({ type, ogTitle: og.mainPage.title, ogDescription: og.mainPage.description });
    }

    analytics.gtag.event('interface sharing', {
      CUSTOM_PARAMETER: `${type?.toLowerCase().replaceAll('-', '')}`,
    });
  };

  return (
    <footer className={css.footer}>
      <div className={css.content}>
        <div className={css.logos}>
          <img src={logoBlue} alt="N12" />
        </div>
        <div className={css.text}>
          <div className={css.title}>{TEXTS.main.footer.texts.title}</div>
          <div className={css.top}>
            <span>{TEXTS.main.footer.texts.top}</span>
          </div>
          <div className={css.bottom}>
            <span
              dangerouslySetInnerHTML={{
                __html: isMobile ? TEXTS.main.footer.texts.bottomMobile : TEXTS.main.footer.texts.bottom,
              }}
            />
          </div>
        </div>
        <div className={css.socials}>
          <div data-id={SHARE_CONSTANTS.MAIL} onClick={clickOnShare}>
            <Icon type="email" />
          </div>
          <div data-id={SHARE_CONSTANTS.TWITTER} onClick={clickOnShare}>
            <Icon type="twitter" />
          </div>
          <div data-id={SHARE_CONSTANTS.FACEBOOK} onClick={clickOnShare}>
            <Icon type="facebook" />
          </div>
        </div>
        <div className={css.rabbiCredit}>
          Developed by&nbsp;
          <a href="https://www.rabbi.co.il" rel="noreferrer" title="רבי אינטראקטיב" target="_blank">
            Rabbi
          </a>
        </div>
      </div>
    </footer>
  );
};
