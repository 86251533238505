import { useRef, useEffect } from 'react';

export default function useUpdateEffect(calback: () => void, dependencies: Array<any>) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return calback();
  }, dependencies);
}
